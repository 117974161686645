import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import "./index.scss";

const DeviceTabs = (prop) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchDataDeviceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataDeviceInfo = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Device_Info/GetByDeviceId?deviceId=${prop.data}`
    )
      .then((rs) => rs.json())
      .then((result) => {
        setProducts(result.data);
      });
  };

  return (
    <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3 mt-4"
    >
      <Tab eventKey="home" title="Mô tả">
        {prop?.description}
      </Tab>
      <Tab eventKey="profile" title="Thông tin bổ sung">
        <Table striped bordered hover>
          <tbody>
            {products.map((item) => (
              <tr key={item.id}>
                <td className="col-3">{item.name}</td>
                <td className="col-9">{item.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Tab>
    </Tabs>
  );
};

export default DeviceTabs;

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  const [footerItem, setFooterItem] = useState([]);

  useEffect(() => {
    fetchDataFooter();
  }, []);

  const fetchDataFooter = () => {
    fetch(`${process.env.REACT_APP_API_URL}/Footer/GetAll`)
      .then((rs) => rs.json())
      .then((result) => {
        setFooterItem(result.data);
      });
  };

  return (
    <div className="text-white mt-5 cus-footer-bg-color">
      <div className="container">
        <footer className="py-5">
          <Row>
            <Col md={6} xs={12}>
              <div className="fs-5 mb-2">
                {footerItem.find((i) => i.code === "CompanyName")?.text}
              </div>
              <div className="fs-6">
                {footerItem.find((i) => i.code === "CompanyDescription")?.text}
              </div>
              <div className="d-flex mt-3">
                {footerItem
                  .filter((x) => x.code === "SocialIcon")
                  ?.map((item, index) => (
                    <Link
                      key={index}
                      to={item.link}
                      className="text-decoration-none fs-6 text-light"
                    >
                      <div
                        className="me-3 cus-icon-footer"
                        dangerouslySetInnerHTML={{ __html: item?.icon }}
                      />
                    </Link>
                  ))}
              </div>
            </Col>
            <Col md={3} xs={12}>
              <div className="fs-5 mb-2">LIÊN HỆ VỚI CHÚNG TÔI</div>

              {footerItem
                .filter((x) => x.code === "PhoneNumber")
                ?.map((item, index) => (
                  <Row key={index}>
                    <Col md={6} xs={6} className="fs-6">
                      {item?.name}
                    </Col>
                    <Col md={6} xs={6} className="fs-6">
                      {item?.text}
                    </Col>
                  </Row>
                ))}
            </Col>
            <Col md={3} xs={12}>
              <div className="fs-5 mb-2">VỀ CHÚNG TÔI</div>
              {footerItem
                .filter((x) => x.code === "About")
                ?.map((item, index) => (
                  <Link
                    key={index}
                    to={item.link}
                    className="text-decoration-none fs-6 text-light"
                  >
                    <div className="cus-footer-link">{item?.text}</div>
                  </Link>
                ))}
            </Col>
          </Row>
          <div className="d-flex flex-column flex-sm-row justify-content-between py-3 my-4 border-top">
            <p>{footerItem.find((i) => i.code === "Copyright")?.text}</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;

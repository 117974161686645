import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import IntroPostItemEdit from "./IntroPostItemEdit";

const IntroPostEdit = () => {
  const { id } = useParams();
  const navigateTo = useNavigate();
  const [post, setPost] = useState(null);

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const fetchData = (postId) => {
    fetch(`${process.env.REACT_APP_API_URL}/IntroPost/Get?id=${postId}`)
      .then((rs) => rs.json())
      .then((result) => {
        setPost(result.data);
      });
  };

  const clickUpdateAll = () => {
    updateDataAll();
  };

  const updateDataAll = () => {
    var myHeaders = new Headers();
    myHeaders.append("content-Type", "application/json");
    myHeaders.append(
      "Access-Control-Allow-Origin",
      `${process.env.REACT_APP_API_URL}`
    );
    myHeaders.append("Access-Control-Allow-Methods", "POST,GET,PUT,OPTIONS");
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "Content-Type, Authorization, X-Requested-With"
    );

    var raw = JSON.stringify(post);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/IntroPost/Update`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.data);
        navigateTo("/intropostlist");
      })
      .catch((error) => {});
  };

  return (
    <div className="container">
      <div className="fs-2 fw-semibold mt-4">Chỉnh sửa bài viết</div>
      <Form className="mt-3">
        <Form.Group className="mb-3" controlId="stt">
          <Form.Label className="fs-5">Số thứ tự</Form.Label>
          <Form.Control
            type="number"
            placeholder="Số thứ tự"
            value={post?.index}
            onChange={(e) =>
              setPost((prevData) => ({
                ...prevData,
                index: e.target.value,
              }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="fs-5">Tiêu đề</Form.Label>
          <Form.Control
            type="text"
            placeholder="Tiêu đề bài viêt"
            value={post?.name}
            onChange={(e) =>
              setPost((prevData) => ({
                ...prevData,
                name: e.target.value,
              }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label className="fs-5">Mô tả</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={post?.description}
            onChange={(e) =>
              setPost((prevData) => ({
                ...prevData,
                description: e.target.value,
              }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label className="fs-5">Các thành phần hiển thị</Form.Label>
          {post?.itemList && <IntroPostItemEdit data={post} />}
        </Form.Group>
      </Form>

      <div className="d-flex flex-row justify-content-between w-100 mt-5">
        <Button variant="outline-success" onClick={clickUpdateAll}>
          Cập nhật dữ liệu
        </Button>
      </div>
    </div>
  );
};

export default IntroPostEdit;

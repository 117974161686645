import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from "uuid";
import HashtagEditModal from "./HashtagEditModal";

const HashtagSetting = () => {
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);

  const handleCloseModal = () => setVisible(false);

  const handleVisible = (item) => {
    setSelected(item);
    setVisible(true);
  };

  const handleSaveModal = () => {
    handleCloseModal();

    setProducts(
      products.map((i) => {
        if (i.id === selected.id) {
          return { id: selected.id, name: selected.name };
        }
        return i;
      })
    );
  };

  const btnAddClick = () => {
    setProducts([
      ...products,
      {
        id: uuidv4(),
        name: "Từ khóa mới",
      },
    ]);
  };

  const deleteItem = (item) => {
    setProducts(products.filter((i) => i.id !== item.id));
  };

  const btnUpdateAllClick = () => {};

  const actionTemplate = (item) => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          variant="outline-warning me-2"
          onClick={() => handleVisible(item)}
        >
          <i className="bi bi-pencil-fill"></i>
        </Button>
        <Button variant="outline-danger" onClick={() => deleteItem(item)}>
          <i className="bi bi-x-lg"></i>
        </Button>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="fs-2 fw-semibold mt-4">Hashtag Setting</div>
      <div className="d-flex flex-row justify-content-between w-100 mt-4">
        <Button variant="outline-primary" onClick={() => btnAddClick()}>
          Thêm mới
        </Button>

        <Button variant="outline-success" onClick={() => btnUpdateAllClick()}>
          Cập nhật dữ liệu
        </Button>
      </div>

      <HashtagEditModal
        show={visible}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        selected={selected}
        setSelected={setSelected}
      />

      <div className="border border-dark mt-3">
        <div className="card">
          <DataTable value={products} tableStyle={{ minWidth: "50rem" }}>
            <Column field="name" header="Name" className="col-8"></Column>

            <Column body={actionTemplate} className="col-4" />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default HashtagSetting;

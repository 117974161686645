import React, { useState, useEffect } from "react";
import "./index.scss";
import DeviceSidebar from "./DeviceSidebar";

const Device = () => {
  const [fixedInfo, setFixedInfo] = useState([]);

  useEffect(() => {
    fetchDataFixedInfo();
  }, []);

  const fetchDataFixedInfo = () => {
    fetch(`${process.env.REACT_APP_API_URL}/FixedInfo/GetAll`)
      .then((rs) => rs.json())
      .then((result) => {
        setFixedInfo(result.data);
      });
  };

  return (
    <div className="container">
      <section className="text-center container">
        <div className="row py-lg-5">
          <div className="col-lg-6 col-md-8 mx-auto">
            <h1 className="fw-light">
              {fixedInfo.find((i) => i.code === "DeviceListText")?.text}
            </h1>
            <p className="lead text-body-secondary">
              {fixedInfo.find((i) => i.code === "DeviceListDescription")?.text}
            </p>
          </div>
        </div>
      </section>
      <DeviceSidebar />
    </div>
  );
};

export default Device;

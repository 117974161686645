import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const ImageChooseModal = ({ show, handleClose, handleSave, imageSrc }) => {
  const [previewImages, setPreviewImages] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const imagesArray = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        imagesArray.push(reader.result);
        if (imagesArray.length === files.length) {
          setPreviewImages(imagesArray);
          setSelectedImg(files[0]);
        }
      };
      reader.readAsDataURL(files[i]);
    }
  };

  const uploadImage = () => {
    var formdata = new FormData();
    formdata.append("file", selectedImg);
    setSelectedImg(null);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}/Upload/UploadImage`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPreviewImages([]);
        handleSave(result.fileName);
      })
      .catch((error) => {});
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Chọn ảnh</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label className="fs-5">Chọn ảnh hiển thị</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </Form.Group>

          <div className="mt-3">
            <div className="fs-5">Ảnh đã chọn:</div>
            <div className="row">
              {previewImages.length < 1 ? (
                <div className="col-md-12">
                  <img
                    src={`/UploadImage/${imageSrc}`}
                    alt={`Preview`}
                    className="img-thumbnail"
                  />
                </div>
              ) : (
                <></>
              )}
              {previewImages.map((image, index) => (
                <div key={index} className="col-md-12">
                  <img
                    src={image}
                    alt={`Preview ${index + 1}`}
                    className="img-thumbnail"
                  />
                </div>
              ))}
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Đóng
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            uploadImage();
          }}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageChooseModal;

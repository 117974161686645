import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./index.scss";
import DeviceAlbum from "./../DeviceAlbum";

const DeviceSidebar = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/DeviceType/GetAll`)
      .then((rs) => rs.json())
      .then((result) => {
        setProducts(result.data);
      });
  };

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={2}>
          <Nav className="flex-column cus-menu-bg-color">
            {products.map((item) => (
              <Nav.Item className="menu-item">
                <Nav.Link eventKey={item.id}>
                  <div className="text-decoration-none cus-text-menu">
                    {item.name}
                  </div>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col sm={10}>
          <Tab.Content>
            {products.map((item) => (
              <Tab.Pane eventKey={item.id}>
                <DeviceAlbum deviceTypeId={item.id} />
              </Tab.Pane>
            ))}
            <Tab.Pane eventKey="first">
              <DeviceAlbum deviceTypeId={null} />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default DeviceSidebar;

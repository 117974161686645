import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";

const Contact = () => {
  const [fixedInfo, setFixedInfo] = useState([]);

  useEffect(() => {
    fetchDataFixedInfo();
  }, []);

  const fetchDataFixedInfo = () => {
    fetch(`${process.env.REACT_APP_API_URL}/FixedInfo/GetAll`)
      .then((rs) => rs.json())
      .then((result) => {
        setFixedInfo(result.data);
      });
  };

  const htmlContactItem = (item) => {
    return (
      <Col md={4}>
        <div className="cus-image-container">
          <img
            src={`/UploadImage/${item.imageSrc}`}
            alt=""
            className="cus-contact-image"
          />
          <div className="cus-text-overlay">
            <div className="fs-3">
              {fixedInfo.find((i) => i.code === `Contact${item.id}`)?.text}
            </div>
            <p class="white-border-bottom" />
            {fixedInfo
              .filter((x) => x.code === `ContactItem${item.id}`)
              ?.map((item, index) => (
                <Row className="mt-2">
                  <Col xs={1}>
                    <div dangerouslySetInnerHTML={{ __html: item?.icon }} />
                  </Col>
                  <Col xs={11}>{item.text}</Col>
                </Row>
              ))}
          </div>
        </div>
      </Col>
    );
  };

  return (
    <div className="container">
      <div className="mt-3 fs-3 color-text">LIÊN HỆ VỚI CHÚNG TÔI</div>
      <p class="mt-3 color-border-bottom" />
      <Row className="mt-4">
        {contactItem.map((item) => htmlContactItem(item))}
      </Row>
    </div>
  );
};

export default Contact;

const contactItem = [
  {
    id: 1,
    imageSrc: "hanoi.jpg",
  },
  {
    id: 2,
    imageSrc: "danang.jpg",
  },
  {
    id: 3,
    imageSrc: "tphochiminh.jpg",
  },
];

import React, { useState, useEffect } from "react";
import "./index.scss";
import IntroCardItem from "./IntroCardItem";

const IntroCard = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/HomeItem/GetByType?type=heading`)
      .then((rs) => rs.json())
      .then((result) => {
        setProducts(result.data);
      });
  };

  return (
    <div className="mt-5 marketing">
      <div className="row justify-content-center">
        {products.map((item) => (
          <IntroCardItem data={item} />
        ))}
      </div>
    </div>
  );
};

export default IntroCard;

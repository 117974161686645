const FeaturetteItem = (item) => {
  return (
    <>
      <hr className="featurette-divider" />
      <div className="row featurette">
        <div
          className={
            "col-md-7" +
            (item.index - item.index / 2 === 0 ? "" : " order-md-2")
          }
        >
          <h2 className="featurette-heading fw-normal lh-1">
            {item.data.name} <span className="text-body-secondary"></span>
          </h2>
          <p className="lead">{item.data.description}</p>
        </div>
        <div className="col-md-5">
          <svg
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
            width="500"
            height="400"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Placeholder: 500x500"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          >
            <title>Placeholder</title>
            <image
              width="500"
              height="400"
              xlinkHref={`/UploadImage/${item.data.imageSrc}`}
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default FeaturetteItem;

import React, { useState, useEffect } from "react";
import "./index.scss";
import FeaturetteItem from "./FeaturetteItem";

const Featurette = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/HomeItem/GetByType?type=featurette`)
      .then((rs) => rs.json())
      .then((result) => {
        setProducts(result.data);
      });
  };

  return (
    <div>
      {products.map((item, index) => (
        <FeaturetteItem data={item} index={index} />
      ))}
    </div>
  );
};

export default Featurette;

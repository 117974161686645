import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import DeviceImagesSetting from "./DeviceImagesSetting";
import DeviceInfoSetting from "./DeviceInfoSetting";

const DeviceDetailSetting = () => {
  const { id } = useParams();
  const navigateTo = useNavigate();
  const [device, setDevice] = useState(null);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [deviceBrands, setDeviceBrands] = useState([]);

  useEffect(() => {
    fetchDataDeviceType();
    fetchDataDeviceBrand();
  }, []);

  useEffect(() => {
    fetchDataDevice(id);
  }, [id]);

  const fetchDataDeviceType = () => {
    fetch(`${process.env.REACT_APP_API_URL}/DeviceType/GetAll`)
      .then((rs) => rs.json())
      .then((result) => {
        setDeviceTypes(result.data);
      });
  };

  const fetchDataDeviceBrand = () => {
    fetch(`${process.env.REACT_APP_API_URL}/DeviceBrand/GetAll`)
      .then((rs) => rs.json())
      .then((result) => {
        setDeviceBrands(result.data);
      });
  };

  const fetchDataDevice = (deviceId) => {
    fetch(`${process.env.REACT_APP_API_URL}/Device/Get?id=${deviceId}`)
      .then((rs) => rs.json())
      .then((result) => {
        setDevice(result.data);
      });
  };

  const clickUpdateAll = () => {
    updateDataAll();
  };

  const updateDataAll = () => {
    var myHeaders = new Headers();
    myHeaders.append("content-Type", "application/json");
    myHeaders.append(
      "Access-Control-Allow-Origin",
      `${process.env.REACT_APP_API_URL}`
    );
    myHeaders.append("Access-Control-Allow-Methods", "POST,GET,PUT,OPTIONS");
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "Content-Type, Authorization, X-Requested-With"
    );

    var raw = JSON.stringify(device);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/Device/Update`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.data);
        navigateTo("/devicesetting");
      })
      .catch((error) => {});
  };

  return (
    <div className="container">
      <div className="fs-2 fw-semibold mt-4">Chi tiết thiết bị</div>
      <Form className="mt-3">
        <Form.Group className="mb-3" controlId="stt">
          <Form.Label className="fs-5">Số thứ tự</Form.Label>
          <Form.Control
            type="number"
            placeholder="Số thứ tự"
            value={device?.index}
            onChange={(e) =>
              setDevice((prevData) => ({
                ...prevData,
                index: e.target.value,
              }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="code">
          <Form.Label className="fs-5">Mã hiệu</Form.Label>
          <Form.Control
            type="text"
            placeholder="Mã hiệu"
            value={device?.code}
            onChange={(e) =>
              setDevice((prevData) => ({
                ...prevData,
                code: e.target.value,
              }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="fs-5">Tên thiết bị</Form.Label>
          <Form.Control
            type="text"
            placeholder="tên thiết bị"
            value={device?.name}
            onChange={(e) =>
              setDevice((prevData) => ({
                ...prevData,
                name: e.target.value,
              }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.Select1">
          <Form.Label className="fs-5">Danh mục thiết bị</Form.Label>
          <Form.Select
            aria-label="Default select example"
            value={device?.deviceTypeId}
            onChange={(e) =>
              setDevice((prevData) => ({
                ...prevData,
                deviceTypeId: e.target.value,
              }))
            }
          >
            {deviceTypes.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
            <option defaultValue>Chưa xác định</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.Select2">
          <Form.Label className="fs-5">Hãng thiết bị</Form.Label>
          <Form.Select
            aria-label="Default select example"
            value={device?.deviceBrandId}
            onChange={(e) =>
              setDevice((prevData) => ({
                ...prevData,
                deviceBrandId: e.target.value,
              }))
            }
          >
            {deviceBrands.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
            <option defaultValue>Chưa xác định</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label className="fs-5">Mô tả</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={device?.description}
            onChange={(e) =>
              setDevice((prevData) => ({
                ...prevData,
                description: e.target.value,
              }))
            }
          />
        </Form.Group>
      </Form>

      <DeviceImagesSetting id={id} />

      <DeviceInfoSetting id={id} />

      <div className="d-flex flex-row justify-content-between w-100 mt-5">
        <Button variant="outline-success" onClick={clickUpdateAll}>
          Cập nhật dữ liệu
        </Button>
      </div>
    </div>
  );
};

export default DeviceDetailSetting;

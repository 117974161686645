import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import Menu from "./Menu";
import Footer from "./Footer";

const Layout = () => {
  return (
    <>
      <Helmet>
        <title>Biztech</title>
        <meta name="description" content="Công ty cổ phần Biztech" />
      </Helmet>

      <Menu />
      <div style={{ minHeight: "63vh" }}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Layout;

import { Link } from "react-router-dom";
import "./index.scss";

const IntroCardItem = (item) => {
  return (
    <div className="col-lg-4">
      <svg
        className="bd-placeholder-img rounded-circle"
        width="140"
        height="140"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Placeholder"
        preserveAspectRatio="xMidYMid slice"
        focusable="false"
      >
        <rect width="100%" height="100%" fill="var(--bs-secondary-color)" />
        <image
          width="140"
          height="140"
          xlinkHref={`/UploadImage/${item.data.imageSrc}`}
        />
      </svg>
      <h2 className="fw-normal">{item.data.name}</h2>
      <p>{item.data.description}</p>
      <p>
        <Link
          className="btn btn-secondary text-decoration-none"
          to={item.data.link ?? "/"}
        >
          Xem chi tiết &raquo;
        </Link>
      </p>
    </div>
  );
};

export default IntroCardItem;

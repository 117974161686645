import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Galleria } from "primereact/galleria";
import Card from "react-bootstrap/Card";
import "./index.scss";

const DeviceAlbumItem = (prop) => {
  const navigateTo = useNavigate();
  const galleria = useRef(null);
  const [products, setProducts] = useState([]);

  const getListImage = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Device_Images/GetByDeviceId?id=${prop.data.id}`
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.data === null || result.data.length === 0) setProducts([]);
        else {
          setProducts(result.data);
        }
      })
      .catch((error) => {});
  };

  const handleClick = () => {
    getListImage();
    // galleria.current.show();
  };

  const itemTemplate = (item) => {
    console.log("item", item);

    return (
      <img
        src={`/UploadImage/${item.imgSource}`}
        alt={item.imgName}
        className="IVN-main-img"
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={`/UploadImage/${item.imgSource}`}
        alt={item.imgName}
        className="IVN-thumbnail"
      />
    );
  };

  return (
    <div className="col">
      <Card className="shadow-sm">
        <svg
          className="bd-placeholder-img"
          width="100%"
          height="275"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder: Thumbnail"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="/55595c" />
          <image
            width="100%"
            height="275"
            xlinkHref={`/UploadImage/${prop.data.imageSrc}`}
          />
        </svg>
        <Card.Body>
          <Card.Title>{prop.data.name}</Card.Title>

          <Card.Text>{prop.data.description}</Card.Text>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => navigateTo(`/devicedetail/${prop.data.id}`)}
              >
                Xem chi tiết
              </button>
            </div>
          </div>

          <div className="flex justify-content-center">
            <Galleria
              ref={galleria}
              value={products}
              responsiveOptions={responsiveOptions}
              numVisible={9}
              style={{ maxWidth: "50%" }}
              circular
              fullScreen
              showItemNavigators
              item={itemTemplate}
              thumbnail={thumbnailTemplate}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DeviceAlbumItem;

const responsiveOptions = [
  {
    breakpoint: "1500px",
    numVisible: 5,
  },
  {
    breakpoint: "1024px",
    numVisible: 3,
  },
  {
    breakpoint: "768px",
    numVisible: 2,
  },
  {
    breakpoint: "560px",
    numVisible: 1,
  },
];

import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { v4 as uuidv4 } from "uuid";
import MenuEditModal from "./MenuEditModal";

const MenuSetting = () => {
  const [nodes, setNodes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [visible, setVisible] = useState(false);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/Menu/GetAll`)
      .then((rs) => rs.json())
      .then((result) => {
        setNodes(result.data);
      });
  };

  const insertData = () => {
    var myHeaders = new Headers();
    myHeaders.append("content-Type", "application/json");
    myHeaders.append(
      "Access-Control-Allow-Origin",
      `${process.env.REACT_APP_API_URL}`
    );
    myHeaders.append("Access-Control-Allow-Methods", "POST,GET,PUT,OPTIONS");
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "Content-Type, Authorization, X-Requested-With"
    );

    var raw = JSON.stringify(nodes);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/Menu/InsertMany`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.data);
        fetchData();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handlevisible = (item) => {
    setSelected(item);
    setVisible(true);
  };

  const handleSaveModal = () => {
    handleCloseModal();
    const updatedNodes = editData(nodes, selected.key, selected.data);
    setNodes(updatedNodes);
  };

  const editData = (tree, key, newData) => {
    return tree.map((node) => {
      if (node.key === key) {
        return { ...node, data: newData };
      } else if (node.children && node.children.length) {
        return { ...node, children: editData(node.children, key, newData) };
      }
      return node;
    });
  };

  const btnAddClick = () => {
    let newId = uuidv4();

    setNodes((prevData) => [
      ...prevData,
      {
        key: newId,
        data: {
          id: newId,
          name: "Menu mới",
          parentId: null,
          index: prevData.length + 1,
          controller: "left",
          actionName: "",
        },
        children: [],
      },
    ]);
  };

  const handleAddChildNode = (node) => {
    let newId = uuidv4();

    const newNode = {
      key: newId,
      data: {
        id: newId,
        name: "Menu Item mới",
        index: node?.children.length + 1,
        parentId: node.key,
        controller: "left",
        actionName: "",
      },
      children: [],
    };

    const updatedNodes = addChildNode(nodes, node.key, newNode);
    setNodes(updatedNodes);
  };

  const addChildNode = (tree, parentKey, newNode) => {
    return tree.map((node) => {
      if (node.key === parentKey) {
        return { ...node, children: [...node.children, newNode] };
      } else if (node.children && node.children.length) {
        return {
          ...node,
          children: addChildNode(node.children, parentKey, newNode),
        };
      }
      return node;
    });
  };

  const deleteItem = (node) => {
    const updatedNodes = removeNode(nodes, node.key);
    setNodes(updatedNodes);
  };

  const removeNode = (tree, key) => {
    return tree.reduce((acc, node) => {
      if (node.key === key) {
        return acc;
      }
      if (node.children && node.children.length) {
        return [...acc, { ...node, children: removeNode(node.children, key) }];
      }
      return [...acc, node];
    }, []);
  };

  const btnUpdateAllClick = () => {
    insertData();
  };

  const actionTemplate = (item) => {
    return (
      <div className="flex flex-wrap gap-2">
        {item.data.parentId === null ? (
          <Button
            variant="outline-primary me-2"
            onClick={() => handleAddChildNode(item)}
          >
            <i className="bi bi-plus-lg"></i>
          </Button>
        ) : (
          <></>
        )}
        <Button
          variant="outline-warning me-2"
          onClick={() => handlevisible(item)}
        >
          <i className="bi bi-pencil-fill"></i>
        </Button>
        <Button variant="outline-danger" onClick={() => deleteItem(item)}>
          <i className="bi bi-x-lg"></i>
        </Button>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="fs-2 fw-semibold mt-4">Menu Setting</div>
      <div className="d-flex flex-row justify-content-between w-100 mt-4">
        <Button variant="outline-primary" onClick={() => btnAddClick()}>
          Thêm mới
        </Button>

        <Button variant="outline-success" onClick={() => btnUpdateAllClick()}>
          Cập nhật dữ liệu
        </Button>
      </div>

      <MenuEditModal
        show={visible}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        selected={selected}
        setSelected={setSelected}
      />

      <div className="border border-dark mt-3">
        <TreeTable value={nodes} tableStyle={{ minWidth: "50rem" }}>
          <Column field="name" header="Tiêu đề" expander />
          <Column field="index" header="STT" />
          {/* <Column field="controller" header="Hiển thị"></Column> */}
          <Column field="actionName" header="Đường dẫn" />

          <Column body={actionTemplate} headerClassName="w-10rem" />
        </TreeTable>
      </div>
    </div>
  );
};

export default MenuSetting;

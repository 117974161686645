import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const PostEdit = () => {
  const { id } = useParams();
  const navigateTo = useNavigate();
  const [post, setPost] = useState(null);

  const options = {
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["underline", "strike", "subscript", "superscript"],
      ["removeFormat"],
      "/",
      ["fontColor", "hiliteColor", "outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image", "video"],
      ["fullScreen", "showBlocks", "codeView"],
    ],
  };

  const handleChange = (html) => {
    setPost((prevData) => ({
      ...prevData,
      content: html,
    }));
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const fetchData = (postId) => {
    fetch(`${process.env.REACT_APP_API_URL}/Post/Get?id=${postId}`)
      .then((rs) => rs.json())
      .then((result) => {
        setPost(result.data);
      });
  };

  const clickUpdateAll = () => {
    updateDataAll();
  };

  const updateDataAll = () => {
    var myHeaders = new Headers();
    myHeaders.append("content-Type", "application/json");
    myHeaders.append(
      "Access-Control-Allow-Origin",
      `${process.env.REACT_APP_API_URL}`
    );
    myHeaders.append("Access-Control-Allow-Methods", "POST,GET,PUT,OPTIONS");
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "Content-Type, Authorization, X-Requested-With"
    );

    var raw = JSON.stringify(post);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/Post/Update`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.data);
        navigateTo("/postlist");
      })
      .catch((error) => {});
  };

  return (
    <div className="container">
      <div className="fs-2 fw-semibold mt-4">Chỉnh sửa bài viết</div>
      <Form className="mt-3">
        <Form.Group className="mb-3" controlId="stt">
          <Form.Label className="fs-5">Số thứ tự</Form.Label>
          <Form.Control
            type="number"
            placeholder="Số thứ tự"
            value={post?.index}
            onChange={(e) =>
              setPost((prevData) => ({
                ...prevData,
                index: e.target.value,
              }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="fs-5">Tiêu đề</Form.Label>
          <Form.Control
            type="text"
            placeholder="Tiêu đề bài viêt"
            value={post?.title}
            onChange={(e) =>
              setPost((prevData) => ({
                ...prevData,
                title: e.target.value,
              }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label className="fs-5">Mô tả</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={post?.description}
            onChange={(e) =>
              setPost((prevData) => ({
                ...prevData,
                description: e.target.value,
              }))
            }
          />
        </Form.Group>
      </Form>

      <div>
        <SunEditor
          height="50vh"
          setOptions={options}
          setContents={post?.content}
          placeholder="Nội dung mô tả chính"
          onChange={handleChange}
        />
        <div>
          <h2>Preview</h2>
          <div dangerouslySetInnerHTML={{ __html: post?.content }} />
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between w-100 mt-5">
        <Button variant="outline-success" onClick={clickUpdateAll}>
          Cập nhật dữ liệu
        </Button>
      </div>
    </div>
  );
};

export default PostEdit;

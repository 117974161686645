import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from "uuid";
import HomePageItemEditModal from "./../HomePageItemEditModal";

const HeadingSetting = () => {
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/HomeItem/GetByType?type=heading`)
      .then((rs) => rs.json())
      .then((result) => {
        setProducts(result.data);
      });
  };

  const insertData = () => {
    var myHeaders = new Headers();
    myHeaders.append("content-Type", "application/json");
    myHeaders.append(
      "Access-Control-Allow-Origin",
      `${process.env.REACT_APP_API_URL}`
    );
    myHeaders.append("Access-Control-Allow-Methods", "POST,GET,PUT,OPTIONS");
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "Content-Type, Authorization, X-Requested-With"
    );

    var raw = JSON.stringify({
      id: uuidv4(),
      name: "Nội dung đầu trang mới",
      description: "",
      imageSrc: "",
      link: "",
      type: "heading",
      index: products.length + 1,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/HomeItem/Insert`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.data);
        fetchData();
      })
      .catch((error) => {});
  };

  const UploadImage = () => {
    var formdata = new FormData();
    formdata.append("file", selectedImg);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}/Upload/UploadImage`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        updateData(result.fileName);
      })
      .catch((error) => {});
  };

  const updateData = (fileName) => {
    var myHeaders = new Headers();
    myHeaders.append("content-Type", "application/json");
    myHeaders.append(
      "Access-Control-Allow-Origin",
      `${process.env.REACT_APP_API_URL}`
    );
    myHeaders.append("Access-Control-Allow-Methods", "POST,GET,PUT,OPTIONS");
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "Content-Type, Authorization, X-Requested-With"
    );

    var raw = JSON.stringify({
      ...selected,
      imageSrc: fileName ?? selected.imageSrc,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/HomeItem/Update`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.data);
        fetchData();
      })
      .catch((error) => {});
  };

  const deleteItem = (item) => {
    fetch(`${process.env.REACT_APP_API_URL}/HomeItem/Delete?id=${item.id}`)
      .then((rs) => rs.json())
      .then((result) => {
        alert(result.data);
        fetchData();
      });
  };

  const handleCloseModal = () => setVisible(false);

  const handleVisible = (item) => {
    setSelected(item);
    setVisible(true);
  };

  const handleSaveModal = () => {
    handleCloseModal();
    if (selectedImg) {
      UploadImage();
    } else {
      updateData();
    }
  };

  const btnAddClick = () => {
    insertData();
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`/UploadImage/${rowData.imageSrc}`}
        alt={rowData.image}
        width="64px"
        className="shadow-4"
      />
    );
  };

  const actionTemplate = (item) => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          variant="outline-warning me-2"
          onClick={() => handleVisible(item)}
        >
          <i className="bi bi-pencil-fill"></i>
        </Button>
        <Button variant="outline-danger" onClick={() => deleteItem(item)}>
          <i className="bi bi-x-lg"></i>
        </Button>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="fs-2 fw-semibold mt-4">
        Quản lý Thông tin đầu Trang chủ
      </div>
      <div className="d-flex flex-row justify-content-between w-100 mt-4">
        <Button variant="outline-primary" onClick={() => btnAddClick()}>
          Thêm mới
        </Button>
      </div>

      <HomePageItemEditModal
        show={visible}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        selected={selected}
        setSelected={setSelected}
        selectedImg={selectedImg}
        setSelectedImg={setSelectedImg}
      />

      <div className="border border-dark mt-3">
        <div className="card">
          <DataTable value={products} tableStyle={{ minWidth: "50rem" }}>
            <Column field="index" header="STT" className="col-1"></Column>
            <Column field="name" header="Tiêu đề" className="col-2"></Column>
            <Column
              field="description"
              header="Mô tả"
              className="col-4"
            ></Column>
            <Column
              header="Ảnh"
              body={imageBodyTemplate}
              className="col-2"
            ></Column>

            <Column body={actionTemplate} className="col-2" />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default HeadingSetting;

import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";

const Banner = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/HomeItem/GetByType?type=banner`)
      .then((rs) => rs.json())
      .then((result) => {
        setProducts(result.data);
      });
  };

  return (
    <Carousel>
      {products.map((item) => (
        <Carousel.Item key={item.id}>
          <img
            height={500}
            src={`/UploadImage/${item.imageSrc}`}
            className="d-block w-100"
            alt="..."
            data-bs-interval="2000"
          />
          <Carousel.Caption>
            <h3>{item.name}</h3>
            <p>{item.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Banner;

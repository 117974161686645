import BannerSetting from "./BannerSetting";
import HeadingSetting from "./HeadingSetting";
import FeaturetteSetting from "./FeaturetteSetting";

const HomePageSetting = () => {
  return (
    <div className="container">
      <BannerSetting />
      <HeadingSetting />
      <FeaturetteSetting />
    </div>
  );
};

export default HomePageSetting;

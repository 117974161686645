import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const FixedInfoSettingEditModal = ({
  show,
  handleClose,
  handleSave,
  selected,
  setSelected,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {selected?.name === null || selected?.name === ""
            ? "Thêm mới"
            : "Chỉnh sửa"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="index">
            <Form.Label>Số thứ tự</Form.Label>
            <Form.Control
              type="number"
              placeholder="Số thứ tự"
              value={selected?.index}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  index: e.target.value,
                }))
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="code">
            <Form.Label>Mã</Form.Label>
            <Form.Control
              type="text"
              placeholder="Mã"
              value={selected?.code}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  code: e.target.value,
                }))
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Tiêu đề</Form.Label>
            <Form.Control
              type="text"
              placeholder="Tiêu đề"
              value={selected?.name}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  name: e.target.value,
                }))
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Nội dung</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              rows={3}
              placeholder="Nội dung"
              value={selected?.text}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  text: e.target.value,
                }))
              }
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="link">
            <Form.Label>Đường dẫn</Form.Label>
            <Form.Control
              type="text"
              placeholder="Đường dẫn"
              value={selected?.link}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  link: e.target.value,
                }))
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="icon">
            <Form.Label>
              Biểu tượng (
              <a href="https://icons.getbootstrap.com/">
                https://icons.getbootstrap.com/
              </a>
              )
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Biểu tượng"
              value={selected?.icon}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  icon: e.target.value,
                }))
              }
            />
            <div className="cus-icon">
              <div dangerouslySetInnerHTML={{ __html: selected?.icon }} />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Đóng
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FixedInfoSettingEditModal;

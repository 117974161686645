import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "./index.scss";

const ProductIntro = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const fetchData = (postId) => {
    fetch(`${process.env.REACT_APP_API_URL}/IntroPost/Get?id=${postId}`)
      .then((rs) => rs.json())
      .then((result) => {
        setPost(result.data);
      });
  };

  return (
    <div className="">
      <Carousel>
        <Carousel.Item>
          <img
            height={400}
            src={`/UploadImage/f24d6bba-6388-46a0-b62a-94c2cda4f2d8_defaultbanner.jpg`}
            className="d-block w-100"
            alt="..."
          />
          <Carousel.Caption className="text-start">
            <h3>{post?.name}</h3>
            <p>{post?.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="container">
        <Row>
          <Col md={6} xs={12} className="mt-5">
            <img
              src={`/UploadImage/${
                post?.itemList?.find((item) => item.type === 1)?.imageSrc
              }`}
              alt="Chưa có ảnh"
              width="100%"
              className="shadow-4"
            />
          </Col>
          <Col md={6} xs={12} className="mt-5">
            <div className="cus-intro-title">Tổng quan</div>
            <hr className="cus-intro-hr" />
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: post?.itemList?.find((item) => item.type === 1)
                    ?.content,
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} xs={12} className="mt-5">
            <div className="cus-intro-title">Hỗ trợ và tác nghiệp</div>
            <hr className="cus-intro-hr" />
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: post?.itemList?.find((item) => item.type === 2)
                    ?.content,
                }}
              />
            </div>
          </Col>
          <Col md={6} xs={12} className="mt-5">
            <div className="cus-intro-title">Kết nối phân hệ khác</div>
            <hr className="cus-intro-hr" />
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: post?.itemList?.find((item) => item.type === 3)
                    ?.content,
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} xs={12} className="mt-5">
            <img
              src={`/UploadImage/${
                post?.itemList?.find((item) => item.type === 4)?.imageSrc
              }`}
              alt="Chưa có ảnh"
              width="100%"
              className="shadow-4"
            />
          </Col>
          <Col md={6} xs={12} className="mt-5">
            <div className="cus-intro-title">
              Những điểm chính trong phân hệ
            </div>
            <hr className="cus-intro-hr" />
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: post?.itemList?.find((item) => item.type === 4)
                    ?.content,
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} xs={12} className="mt-5">
            <div className="cus-intro-title">Báo cáo</div>
            <hr className="cus-intro-hr" />
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: post?.itemList?.find((item) => item.type === 5)
                    ?.content,
                }}
              />
            </div>
          </Col>
          <Col md={6} xs={12} className="mt-5">
            <img
              src={`/UploadImage/${
                post?.itemList?.find((item) => item.type === 5)?.imageSrc
              }`}
              alt="Chưa có ảnh"
              width="100%"
              className="shadow-4"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProductIntro;

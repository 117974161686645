import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";

const Login = () => {
  const [user, setUser] = useState([]);
  const navigateTo = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("UserIn4")) {
      sessionStorage.removeItem("UserIn4");
      sessionStorage.clear();
      window.location.reload();
    }
  }, []);

  const onSubmit = () => {
    if (user.username === "Admin" && user.password === "12345678") {
      if (typeof Storage !== "undefined") {
        sessionStorage.setItem("UserIn4", JSON.stringify(user));
      } else {
        alert("Trình duyệt của bạn không hỗ trợ!");
      }
      alert("Đăng nhập thành công");
      navigateTo("/");
      window.location.reload();
    } else {
      alert("Đăng nhập thất bại");
    }
  };

  return (
    <div>
      <main className="form-signin w-100 m-auto">
        <h1 className="h3 mb-3 fw-normal">Đăng nhập</h1>

        <div className="form-floating">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="name@example.com"
            value={user?.username}
            onChange={(e) =>
              setUser((prevUserData) => ({
                ...prevUserData,
                username: e.target.value,
              }))
            }
          />
          <label for="floatingInput">Tài khoản</label>
        </div>
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Password"
            value={user?.password}
            onChange={(e) =>
              setUser((prevUserData) => ({
                ...prevUserData,
                password: e.target.value,
              }))
            }
          />
          <label for="floatingPassword">Mật khẩu</label>
        </div>

        <button className="btn btn-primary w-100 py-2" onClick={onSubmit}>
          Đăng nhập
        </button>
      </main>
    </div>
  );
};

export default Login;

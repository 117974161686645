import React from "react";
import { Modal, Button } from "react-bootstrap";

const PreviewModal = ({ content, showPreview, setShowPreview }) => {
  return (
    <Modal
      centered
      fullscreen
      show={showPreview}
      onHide={() => setShowPreview(false)}
    >
      <Modal.Header className="container" closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="container">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Modal.Body>

      <Modal.Footer className="container">
        <Button variant="secondary" onClick={() => setShowPreview(false)}>
          Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewModal;

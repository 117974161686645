import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "react-bootstrap/Button";
import SunEditorModal from "../../../component/SunEditorModal";
import ImageChooseModal from "../../../component/ImageChooseModal";

const IntroPostItemEdit = (prop) => {
  const [selected, setSelected] = useState(null);
  const [content, setContent] = useState(null);
  const [visibleImage, setVisibleImage] = useState(false);
  const [visibleSunEditor, setVisibleSunEditor] = useState(false);
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    setItemList(prop?.data?.itemList ?? []);
  }, [prop]);

  const fetchData = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/IntroPost/GetItemsByIntroPostId?postId=${prop?.data?.id}`
    )
      .then((rs) => rs.json())
      .then((result) => {
        setItemList(result.data);
      });
  };

  const updateData = (item) => {
    var myHeaders = new Headers();
    myHeaders.append("content-Type", "application/json");
    myHeaders.append(
      "Access-Control-Allow-Origin",
      `${process.env.REACT_APP_API_URL}`
    );
    myHeaders.append("Access-Control-Allow-Methods", "POST,GET,PUT,OPTIONS");
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "Content-Type, Authorization, X-Requested-With"
    );

    var raw = JSON.stringify(item);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/IntroPost/UpdateItem`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        alert(result.data);
        fetchData();
      })
      .catch((error) => {});
  };

  const handleCloseImageModal = () => setVisibleImage(false);

  const handleVisibleImage = (item) => {
    setSelected(item);
    setVisibleImage(true);
  };

  const handleSaveImageModal = (src) => {
    updateData({
      ...selected,
      imageSrc: src,
    });
    handleCloseImageModal();
  };

  const handleCloseSunEditorModal = () => setVisibleSunEditor(false);

  const handleVisibleSunEditor = (item) => {
    setSelected(item);
    setContent(item?.content);
    setVisibleSunEditor(true);
  };

  const handleSaveSunEditorModal = () => {
    updateData({
      ...selected,
      content: content,
    });
    handleCloseSunEditorModal();
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`/UploadImage/${rowData.imageSrc}`}
        alt={rowData.image}
        width="64px"
        className="shadow-4"
      />
    );
  };

  const actionTemplate = (item) => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          variant="outline-success me-2"
          onClick={() => handleVisibleImage(item)}
        >
          <i class="bi bi-card-image"></i>
        </Button>
        <Button
          variant="outline-warning me-2"
          onClick={() => handleVisibleSunEditor(item)}
        >
          <i className="bi bi-pencil-fill"></i>
        </Button>
      </div>
    );
  };

  return (
    <div>
      <div className="border border-dark mt-3">
        <div className="card">
          <DataTable value={itemList} tableStyle={{ minWidth: "50rem" }}>
            <Column field="index" header="STT" className="col-1"></Column>
            <Column field="name" header="Tên" className="col-4"></Column>
            <Column
              field="imageSrc"
              header="Ảnh"
              body={imageBodyTemplate}
              className="col-4"
            ></Column>

            <Column body={actionTemplate} className="col-3" />
          </DataTable>
        </div>
      </div>

      <ImageChooseModal
        show={visibleImage}
        handleClose={handleCloseImageModal}
        handleSave={handleSaveImageModal}
        imageSrc={selected?.imageSrc}
      />

      <SunEditorModal
        show={visibleSunEditor}
        handleClose={handleCloseSunEditorModal}
        handleSave={handleSaveSunEditorModal}
        content={content}
        setContent={setContent}
      />
    </div>
  );
};

export default IntroPostItemEdit;

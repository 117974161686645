import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from "uuid";

const PostListSetting = () => {
  const navigateTo = useNavigate();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/Post/GetAll`)
      .then((rs) => rs.json())
      .then((result) => {
        setPosts(result.data);
      });
  };

  const insertData = () => {
    var myHeaders = new Headers();
    myHeaders.append("content-Type", "application/json");
    myHeaders.append(
      "Access-Control-Allow-Origin",
      `${process.env.REACT_APP_API_URL}`
    );
    myHeaders.append("Access-Control-Allow-Methods", "POST,GET,PUT,OPTIONS");
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "Content-Type, Authorization, X-Requested-With"
    );

    var raw = JSON.stringify({
      id: uuidv4(),
      title: "Bài viết mới",
      description: "",
      imageSrc: "",
      videoSrc: "",
      link: "",
      content: "",
      index: posts.length + 1,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/Post/Insert`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.data);
        fetchData();
      })
      .catch((error) => {});
  };

  const deleteItem = (item) => {
    fetch(`${process.env.REACT_APP_API_URL}/Post/Delete?id=${item.id}`)
      .then((rs) => rs.json())
      .then((result) => {
        alert(result.data);
        fetchData();
      });
  };

  const btnAddClick = () => {
    insertData();
  };

  const actionTemplate = (item) => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          variant="outline-warning me-2"
          onClick={() => navigateTo(`/postedit/${item.id}`)}
        >
          <i className="bi bi-pencil-fill"></i>
        </Button>
        <Button variant="outline-danger" onClick={() => deleteItem(item)}>
          <i className="bi bi-x-lg"></i>
        </Button>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="fs-2 fw-semibold mt-4">Quản lý Bài viết cơ bản</div>
      <div className="d-flex flex-row justify-content-between w-100 mt-4">
        <Button variant="outline-primary" onClick={() => btnAddClick()}>
          Thêm mới
        </Button>
      </div>

      <div className="border border-dark mt-3">
        <div className="card">
          <DataTable value={posts} tableStyle={{ minWidth: "50rem" }}>
            <Column field="index" header="STT" className="col-1"></Column>
            <Column field="title" header="Tiêu đề" className="col-3"></Column>
            <Column
              field="description"
              header="Mô tả"
              className="col-6"
            ></Column>

            <Column body={actionTemplate} className="col-2" />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default PostListSetting;

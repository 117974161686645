import React, { useState, useEffect } from "react";
import { Galleria } from "primereact/galleria";
import "./index.scss";

const DeviceGalleria = (prop) => {
  const [products, setProducts] = useState([]);
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];

  // useEffect(() => {
  //   setImages([
  //     {
  //       itemImageSrc:
  //         "https://primefaces.org/cdn/primereact/images/galleria/galleria1.jpg",
  //       thumbnailImageSrc:
  //         "https://primefaces.org/cdn/primereact/images/galleria/galleria1s.jpg",
  //       alt: "Description for Image 1",
  //       title: "Title 1",
  //     },
  //     {
  //       itemImageSrc:
  //         "https://primefaces.org/cdn/primereact/images/galleria/galleria1.jpg",
  //       thumbnailImageSrc:
  //         "https://primefaces.org/cdn/primereact/images/galleria/galleria1s.jpg",
  //       alt: "Description for Image 1",
  //       title: "Title 1",
  //     },
  //     {
  //       itemImageSrc:
  //         "https://primefaces.org/cdn/primereact/images/galleria/galleria1.jpg",
  //       thumbnailImageSrc:
  //         "https://primefaces.org/cdn/primereact/images/galleria/galleria1s.jpg",
  //       alt: "Description for Image 1",
  //       title: "Title 1",
  //     },
  //   ]);
  // }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Device_Images/GetByDeviceId?id=${prop.data}`
    )
      .then((rs) => rs.json())
      .then((result) => {
        setProducts(result.data);
      });
  };

  const itemTemplate = (item) => {
    return (
      <img
        src={`/UploadImage/${item.imgSource}`}
        alt={item.ImgName}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={`/UploadImage/${item.imgSource}`}
        alt={item.ImgName}
        style={{ height: "80px", display: "block" }}
      />
    );
  };

  return (
    <div className="card">
      <Galleria
        value={products}
        responsiveOptions={responsiveOptions}
        numVisible={2}
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
      />
    </div>
  );
};

export default DeviceGalleria;

import { Link } from "react-router-dom";
import "./index.scss";

const DeviceBreadcrumb = () => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
        <li className="breadcrumb-item">
          <Link
            className="link-body-emphasis fw-semibold text-decoration-none"
            to="/device"
          >
            Thiết bị
          </Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Máy chấm công
        </li>
      </ol>
    </nav>
  );
};

export default DeviceBreadcrumb;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import DeviceGalleria from "./DeviceGalleria";
import DeviceBreadcrumb from "./DeviceBreadcrumb";
import DeviceTabs from "./DeviceTabs";

const DeviceDetail = () => {
  const { id } = useParams();
  const [device, setDevice] = useState(null);

  useEffect(() => {
    fetchDataDevice(id);
  }, [id]);

  const fetchDataDevice = (deviceId) => {
    fetch(`${process.env.REACT_APP_API_URL}/Device/Get?id=${deviceId}`)
      .then((rs) => rs.json())
      .then((result) => {
        setDevice(result.data);
      });
  };

  return (
    <div className="container mt-5">
      <Row>
        <Col md={6} xs={12}>
          <DeviceGalleria data={id} />
        </Col>

        <Col md={6} xs={12}>
          <DeviceBreadcrumb />

          <div className="p-3">
            <p className="cus-font-roboto cus-fontsize-17em cus-color-014682 fw-bolder">
              {device?.name}
            </p>
          </div>

          <div>
            {device && (
              <DeviceTabs data={id} description={device?.description} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DeviceDetail;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from "uuid";

const DeviceSetting = () => {
  const navigateTo = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/Device/GetAll`)
      .then((rs) => rs.json())
      .then((result) => {
        setProducts(result.data);
      });
  };

  const insertData = () => {
    var myHeaders = new Headers();
    myHeaders.append("content-Type", "application/json");
    myHeaders.append(
      "Access-Control-Allow-Origin",
      `${process.env.REACT_APP_API_URL}`
    );
    myHeaders.append("Access-Control-Allow-Methods", "POST,GET,PUT,OPTIONS");
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "Content-Type, Authorization, X-Requested-With"
    );

    var raw = JSON.stringify({
      id: uuidv4(),
      code: "",
      name: "Thiết bị mới",
      deviceTypeId: null,
      deviceBrandId: null,
      description: "",
      index: products.length + 1,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/Device/Insert`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.data);
        fetchData();
      })
      .catch((error) => {});
  };

  const deleteItem = (item) => {
    fetch(`${process.env.REACT_APP_API_URL}/Device/Delete?id=${item.id}`)
      .then((rs) => rs.json())
      .then((result) => {
        alert(result.data);
        fetchData();
      });
  };

  const updateItem = (item) => {
    navigateTo(`/devicedetailsetting/${item.id}`);
  };

  const btnAddClick = () => {
    insertData();
  };

  const actionTemplate = (item) => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button variant="outline-warning me-2" onClick={() => updateItem(item)}>
          <i className="bi bi-pencil-fill"></i>
        </Button>
        <Button variant="outline-danger" onClick={() => deleteItem(item)}>
          <i className="bi bi-x-lg"></i>
        </Button>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="fs-2 fw-semibold mt-4">Quản lý thiết bị</div>
      <div className="d-flex flex-row justify-content-between w-100 mt-4">
        <Button variant="outline-primary" onClick={() => btnAddClick()}>
          Thêm mới
        </Button>
      </div>

      <div className="border border-dark mt-3">
        <div className="card">
          <DataTable value={products} tableStyle={{ minWidth: "50rem" }}>
            <Column field="index" header="STT" className="col-1"></Column>
            <Column field="code" header="Mã hiệu" className="col-3"></Column>
            <Column field="name" header="Tên" className="col-3"></Column>

            <Column body={actionTemplate} className="col-5" />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default DeviceSetting;

import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import "./Menu.scss";

const Menu = () => {
  const [user, setUser] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [activeNavDropdown, setActiveNavDropdown] = useState(null);

  useEffect(() => {
    setUser(sessionStorage.getItem("UserIn4"));
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/Menu/GetAll`)
      .then((rs) => rs.json())
      .then((result) => {
        setNodes(result.data);
      });
  };

  const handleSelect = (selectedKey) => {
    setActiveItem(selectedKey);
    setActiveNavDropdown(null);
  };

  const handleClickNavDropdown = (item) => {
    setActiveNavDropdown(item);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary sticky-top cus-menu-bg-color"
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            src={`/UploadImage/logo-wide.png`}
            className="d-block cus-logo"
            alt="..."
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="me-auto"
            activeKey={activeItem}
            onSelect={handleSelect}
          >
            {user === null ? (
              <>
                {nodes?.map((node) => (
                  <>
                    {node.children.length > 0 ? (
                      <NavDropdown
                        key={node.key}
                        eventKey={node.key}
                        title={node.data.name}
                        id="collapsible-nav-dropdown"
                        className={`cus-text-menu ${
                          activeNavDropdown === node.key ? " active-drop " : " "
                        }`}
                        onClick={() => handleClickNavDropdown(node.key)}
                      >
                        {node.children.map((item) => (
                          <NavDropdown.Item key={item.key} eventKey={item.key}>
                            <Link
                              className="text-decoration-none cus-text-menu"
                              to={item.data.actionName}
                            >
                              {item.data.name}
                            </Link>
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    ) : (
                      <Nav.Link key={node.key} eventKey={node.key}>
                        <Link
                          className="text-decoration-none cus-text-menu"
                          to={node.data.actionName}
                        >
                          {node.data.name}
                        </Link>
                      </Nav.Link>
                    )}
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </Nav>

          <Nav activeKey={activeItem} onSelect={handleSelect}>
            {user === null ? (
              <></>
            ) : (
              <>
                <NavDropdown
                  title="Bài viết"
                  id="collapsible-nav-dropdown"
                  className="cus-text-menu"
                >
                  <NavDropdown.Item>
                    <Link
                      className="text-decoration-none cus-text-menu"
                      to="/postlist"
                    >
                      Cơ bản
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="text-decoration-none cus-text-menu"
                      to="/intropostlist"
                    >
                      Giới thiệu sản phẩm
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="Cài đặt"
                  id="collapsible-nav-dropdown"
                  className="cus-text-menu"
                >
                  <NavDropdown.Item>
                    <Link
                      className="text-decoration-none cus-text-menu"
                      to="/menusetting"
                    >
                      Mục lục
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="text-decoration-none cus-text-menu"
                      to="/homepagesetting"
                    >
                      Trang chủ
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="text-decoration-none cus-text-menu"
                      to="/hashtagsetting"
                    >
                      Thẻ tìm kiếm
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="text-decoration-none cus-text-menu"
                      to="/fixedinfosetting"
                    >
                      Thông tin trên trang
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="text-decoration-none cus-text-menu"
                      to="/footersetting"
                    >
                      Chân trang
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="Thiết bị"
                  id="collapsible-nav-dropdown"
                  className="cus-text-menu"
                >
                  <NavDropdown.Item>
                    <Link
                      className="text-decoration-none cus-text-menu"
                      to="/devicesetting"
                    >
                      Thiết bị
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="text-decoration-none cus-text-menu"
                      to="/devicetypesetting"
                    >
                      Loại thiết bị
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="text-decoration-none cus-text-menu"
                      to="/devicebrandsetting"
                    >
                      Hãng thiết bị
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
            <Nav.Link>
              <Link className="text-decoration-none cus-text-menu" to="/login">
                {user === null ? "Đăng nhập" : "Đăng xuất"}
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;

import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Device from "./pages/Device";
import DeviceDetail from "./pages/Device/DeviceDetail";
import MenuSetting from "./pages/MenuSetting";
import HomePageSetting from "./pages/HomePageSetting";
import HashtagSetting from "./pages/HashtagSetting";
import DeviceSetting from "./pages/DeviceSetting";
import DeviceTypeSetting from "./pages/DeviceType";
import DeviceBrandSetting from "./pages/DeviceBrand";
import DeviceDetailSetting from "./pages/DeviceDetailSetting";
import Login from "./pages/Login";
import Post from "./pages/Post";
import PostList from "./pages/PostList";
import PostEdit from "./pages/PostEdit";
import ProductIntro from "./pages/ProductIntro";
import IntroPostList from "./pages/IntroPostList";
import IntroPostEdit from "./pages/IntroPostEdit";
import FixedInfoSetting from "./pages/FixedInfoSetting";
import FooterSetting from "./pages/FooterSetting";
import Contact from "./pages/Contact";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="device" element={<Device />} />
        <Route path="devicedetail/:id/*" element={<DeviceDetail />} />
        <Route path="menusetting" element={<MenuSetting />} />
        <Route path="homepagesetting" element={<HomePageSetting />} />
        <Route path="hashtagsetting" element={<HashtagSetting />} />
        <Route path="devicesetting" element={<DeviceSetting />} />
        <Route
          path="devicedetailsetting/:id/*"
          element={<DeviceDetailSetting />}
        />
        <Route path="devicetypesetting" element={<DeviceTypeSetting />} />
        <Route path="devicebrandsetting" element={<DeviceBrandSetting />} />
        <Route path="about" element={<About />} />
        <Route path="login" element={<Login />} />
        <Route path="post/:id/*" element={<Post />} />
        <Route path="postlist" element={<PostList />} />
        <Route path="postedit/:id/*" element={<PostEdit />} />
        <Route path="productintro/:id/*" element={<ProductIntro />} />
        <Route path="intropostlist" element={<IntroPostList />} />
        <Route path="intropostedit/:id/*" element={<IntroPostEdit />} />
        <Route path="fixedinfosetting" element={<FixedInfoSetting />} />
        <Route path="contact" element={<Contact />} />
        <Route path="footersetting" element={<FooterSetting />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;

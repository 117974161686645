import React, { useState, useEffect } from "react";
import "./index.scss";
import DeviceAlbumItem from "./DeviceAlbumItem";

const DeviceAlbum = (item) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const fetchData = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Device/Filter?deviceTypeId=${
        item.deviceTypeId ?? ""
      }`
    )
      .then((rs) => rs.json())
      .then((result) => {
        setProducts(result.data);
      });
  };
  return (
    <div className="album  bg-body-tertiary">
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
          {products.map((item) => (
            <DeviceAlbumItem data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeviceAlbum;

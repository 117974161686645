import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./index.scss";

const Post = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const fetchData = (postId) => {
    fetch(`${process.env.REACT_APP_API_URL}/Post/Get?id=${postId}`)
      .then((rs) => rs.json())
      .then((result) => {
        setPost(result.data);
      });
  };

  return (
    <div className="container">
      <div className="col-12 d-flex flex-row justify-content-center mt-4">
        <div className="fs-2 fw-semibold cus-text-dark-blue">{post?.title}</div>
      </div>
      <div className="mt-5">
        <div dangerouslySetInnerHTML={{ __html: post?.content }} />
      </div>
    </div>
  );
};

export default Post;

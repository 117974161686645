import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const DeviceImagesEditModal = ({
  show,
  handleClose,
  handleSave,
  selected,
  setSelected,
  selectedImg,
  setSelectedImg,
}) => {
  const [previewImages, setPreviewImages] = useState([]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const imagesArray = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        imagesArray.push(reader.result);
        if (imagesArray.length === files.length) {
          setPreviewImages(imagesArray);
          setSelectedImg(files[0]);
        }
      };
      reader.readAsDataURL(files[i]);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Chỉnh sửa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="index">
            <Form.Label>Số thứ tự</Form.Label>
            <Form.Control
              type="number"
              placeholder="Số thứ tự"
              value={selected?.index}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  index: e.target.value,
                }))
              }
            />
          </Form.Group>
          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label className="fs-5">Chọn ảnh hiển thị</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </Form.Group>

          <div className="mt-3">
            <div className="fs-5">Ảnh đã chọn:</div>
            <div className="row">
              {previewImages.length < 1 ? (
                <div className="col-md-3">
                  <img
                    src={`/UploadImage/${selected.imageSrc}`}
                    alt={`Preview`}
                    className="img-thumbnail"
                  />
                </div>
              ) : (
                <></>
              )}
              {previewImages.map((image, index) => (
                <div key={index} className="col-md-3">
                  <img
                    src={image}
                    alt={`Preview ${index + 1}`}
                    className="img-thumbnail"
                  />
                </div>
              ))}
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Đóng
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setPreviewImages([]);
            handleSave();
          }}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeviceImagesEditModal;

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import PreviewModal from "../PreviewModal";

const SunEditorModal = ({
  show,
  handleClose,
  handleSave,
  content,
  setContent,
}) => {
  const options = {
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["underline", "strike", "subscript", "superscript"],
      ["removeFormat"],
      "/",
      ["fontColor", "hiliteColor", "outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image", "video"],
      ["fullScreen", "showBlocks", "codeView"],
    ],
  };

  const [showPreview, setShowPreview] = useState(false);

  const handleChange = (html) => {
    setContent(html);
  };

  return (
    <>
      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SunEditor
            height="50vh"
            setOptions={options}
            setContents={content}
            placeholder="Nội dung mô tả chính"
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row justify-content-between">
          <Button variant="success" onClick={() => setShowPreview(true)}>
            Preview
          </Button>
          <div>
            <Button className="me-3" variant="secondary" onClick={handleClose}>
              Đóng
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Lưu
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <PreviewModal
        content={content}
        showPreview={showPreview}
        setShowPreview={setShowPreview}
      />
    </>
  );
};

export default SunEditorModal;

import Container from "react-bootstrap/Container";
import "./index.scss";
import Banner from "./Banner";
import IntroCard from "./IntroCard";
import Featurette from "./Featurette";

const Home = () => {
  return (
    <>
      <Banner />
      <Container>
        <IntroCard />
        <Featurette />
      </Container>
    </>
  );
};

export default Home;

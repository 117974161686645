import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const MenuEditModal = ({
  show,
  handleClose,
  handleSave,
  selected,
  setSelected,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {selected?.data?.name === null || selected?.data?.name === ""
            ? "Thêm mới"
            : "Chỉnh sửa"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Tiêu đề</Form.Label>
            <Form.Control
              type="text"
              placeholder="Tiêu đề"
              value={selected?.data?.name}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  data: {
                    ...prevData.data,
                    name: e.target.value,
                  },
                }))
              }
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>STT</Form.Label>
            <Form.Control
              type="number"
              placeholder="Số thứ tự"
              value={selected?.data?.index}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  data: {
                    ...prevData.data,
                    index: e.target.value,
                  },
                }))
              }
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Menu hiển thị</Form.Label>

            <Form.Select
              value={selected?.data?.controller}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  data: {
                    ...prevData.data,
                    controller: e.target.value,
                  },
                }))
              }
            >
              <option value="left">left</option>
              <option value="right">right</option>
            </Form.Select>
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Đường dẫn</Form.Label>
            <Form.Control
              type="text"
              placeholder="Đường dẫn"
              value={selected?.data?.actionName}
              onChange={(e) =>
                setSelected((prevData) => ({
                  ...prevData,
                  data: {
                    ...prevData.data,
                    actionName: e.target.value,
                  },
                }))
              }
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Đóng
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MenuEditModal;
